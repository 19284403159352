<template>
    <v-row class="my-3">
        <v-col cols="12" md="12">
            <v-card class="mx-auto" max-width="344">
                <div class="d-flex flex-no-wrap">
                    <v-img src="../assets/hamburguesa_1.png" width="81px"></v-img>
                    <div class="pl-2">
                        <div class="subtitle-2 font-weight-bold">
                            Hamburguesa Clásica de Carne
                        </div>
                        <div class="text-caption">
                            Hamburguesa Clásica de Carne
                        </div>
                        <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                            S/12.00
                        </div>
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col cols="12" md="12">
            <v-card class="mx-auto" max-width="344">
                <div class="d-flex flex-no-wrap">
                    <v-img src="../assets/hamburguesa_2.png" width="81px"></v-img>
                    <div class="pl-2">
                        <div class="subtitle-2 font-weight-bold">
                            Hamburguesa doble con queso
                        </div>
                        <div class="text-caption">
                            Hamburguesa doble con queso
                        </div>
                        <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                            S/15.00
                        </div>
                    </div>
                </div>

            </v-card>
        </v-col>
        <v-col cols="12" md="12">
            <v-card class="mx-auto" max-width="344">
                <div class="d-flex flex-no-wrap">
                    <v-img src="../assets/gaseosa.jpg" width="81px"></v-img>
                    <div class="pl-2">
                        <div class="subtitle-2 font-weight-bold">
                            Inca Cola 500ml
                        </div>
                        <div class="text-caption">
                            Inca Cola 500 ml
                        </div>
                        <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                            S/5.00
                        </div>
                    </div>
                </div>

            </v-card>
        </v-col>
        <v-col cols="12" md="12">
            <v-card class="mx-auto" max-width="344">
                <div class="d-flex flex-no-wrap">
                    <v-img src="../assets/Product.png" width="81px"></v-img>
                    <div class="pl-2">
                        <div class="subtitle-2 font-weight-bold">
                            Spaghetti al Aglio e Olio
                        </div>
                        <div class="text-caption">
                            Spaghetti en ajo, aceite y finas hierbas, acompañado de champiñones.
                        </div>
                        <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                            S/32.00
                        </div>
                    </div>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>
<style scoped>
.white--text {
    color: white;
}

.subtitle-2 {
    color: #070D56;
}
</style>
<script>
export default {
    name: 'Product',
}
</script>
