<template>
    <div  class="d-flex align-center" style="background: linear-gradient(180deg, #0066FF 0%, #00CCFF 100%);height:100vh;">
        <v-container>
            <v-row>
                <v-col  cols="10" sm="10" md="10" class="bg-white text-center mx-auto" style="background: #FFFFFF;
                    border-radius: 30px;">
                    
        <v-img src="../assets/Group.png" contain width="58" class="align-center d-flex mx-auto my-6">
            
        </v-img>
                <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                    Hemos confirmado
                </div>
                <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                    tu pedido
                </div>
                <p style="color: #828282;" class="mt-4">Tu número de pedido es el</p>
                <div class="subtitle-2 font-weight-bold my-4" style="color: #0066FF;">
                    064
                </div>
                <p style="color: #828282;">En un momento estaremos llevando tu pedido</p>
                <v-btn block outlined elevation="0" class="white--text my-6" color="#0066FF" :to="'/'">Ir a inicio</v-btn>
                </v-col>

            </v-row>
        </v-container>
    </div>
</template>
<style scoped>
.white--text {
    color: white;
}
</style>
