<template>
    <div>
        <v-img src="../assets/Rectangle3.png" class="white--text align-start text-left">
            <div class="font-weight-medium subtitle-">Menu</div>
        </v-img>
        <div style="border-radius: 20px 20px 0 0;background-color: white;">
            <v-container>
                <div class="subtitle-2 font-weight-bold">
                    Spaghetti al Aglio e Olio
                </div>
                <div class="text-caption">
                    Spaghetti en ajo, aceite y finas hierbas, acompañado de champiñones.
                </div>
                <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                    S/32.00
                </div>
                <div class="subtitle-2 font-weight-bold">
                    Servicio
                </div>
                <v-row>
                    <v-col cols="6" sm="6" md="6">
                        <v-checkbox label="En mesa" color="#0066FF" value="mesa" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-checkbox label="Para llevar" color="#0066FF" value="Para llevar" hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <div class="subtitle-2 font-weight-bold">
                    Adicionales
                </div>
                <div class="d-flex flex-row flex-no-wrap justify-space-between">
                    <div>
                        <div class="subtitle-2 font-weight-bold">Queso mozzarella
                        </div>
                        <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                            S/ 1.00
                        </div>
                    </div>
                    <div class="flex-row d-flex flex-no-wrap justify-space-between align-center">
                        <v-icon color="#0066FF">mdi-plus-circle-outline</v-icon>
                        <div class="subtitle-2 font-weight-bold mx-3">
                            0
                        </div>
                        <v-icon color="#0066FF">mdi-minus-circle-outline</v-icon>

                    </div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex flex-row flex-no-wrap justify-space-between">
                    <div>
                        <div class="subtitle-2 font-weight-bold">Jamón
                        </div>
                        <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                            S/ 1.00
                        </div>
                    </div>
                    <div class="flex-row d-flex flex-no-wrap justify-space-between align-center">
                        <v-icon color="#0066FF">mdi-plus-circle-outline</v-icon>
                        <div class="subtitle-2 font-weight-bold mx-3">
                            0
                        </div>
                        <v-icon color="#0066FF">mdi-minus-circle-outline</v-icon>

                    </div>
                </div>
<!--                <v-divider></v-divider>
                <div class="d-flex flex-row flex-no-wrap justify-space-between">
                    <div>
                        <div class="subtitle-2 font-weight-bold">Tocino
                        </div>
                        <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                            S/ 1.00
                        </div>
                    </div>
                    <div class="flex-row d-flex flex-no-wrap justify-space-between align-center">
                        <v-icon color="#0066FF">mdi-plus-circle-outline</v-icon>
                        <div class="subtitle-2 font-weight-bold mx-3">
                            3
                        </div>
                        <v-icon color="#0066FF">mdi-minus-circle-outline</v-icon>

                    </div>
                </div>-->
                <div class="flex-row d-flex flex-no-wrap justify-space-between align-center py-3">
                    <v-icon color="#0066FF">mdi-plus-circle-outline</v-icon>
                    <div class="subtitle-2 font-weight-bold mx-3">
                        0
                    </div>
                    <v-icon color="#0066FF">mdi-minus-circle-outline</v-icon>
                    <v-btn outlined color="#0066FF" class="white--text" :to="'checkout'">Agregar (3) items</v-btn>
                </div>
            </v-container>
        </div>
    </div>
</template>
<style scoped>
.white--text {
    color: white;
}

.subtitle-2 {
    color: #070D56;
}
</style>
<script>
export default {
    data() {
        return {
            value: 1,
            active: true,
        }
    },
}
</script>
