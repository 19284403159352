<template>
    <div>
        <div class="overflow-hidden">
            <div class="text-center mb-8">
                <NavBar/>
                <v-container>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field outlined label="Buscar" prepend-inner-icon="mdi-magnify"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-tabs v-model="tab">
                        <v-tab value="one" color="#03C3F4" outlined>Todas</v-tab>
                        <v-tab value="two">Clásicas</v-tab>
                        <v-tab value="three">Extras</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item value="one">
<!--                            <Product/>-->
                            <v-row class="my-3">
                                <v-col cols="12" md="12">
                                    <v-card class="mx-auto" max-width="344" @click="addProduct(1)">
                                        <div class="d-flex flex-no-wrap">
                                            <v-img src="../assets/hamburguesa_1.png" width="81px"></v-img>
                                            <div class="pl-2">
                                                <div class="subtitle-2 font-weight-bold">
                                                    Hamburguesa Clásica de Carne
                                                </div>
                                                <div class="text-caption">
                                                    Hamburguesa Clásica de Carne
                                                </div>
                                                <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                                                    S/12.00
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-card class="mx-auto" max-width="344" @click="addProduct(2)">
                                        <div class="d-flex flex-no-wrap">
                                            <v-img src="../assets/hamburguesa_2.png" width="81px"></v-img>
                                            <div class="pl-2">
                                                <div class="subtitle-2 font-weight-bold">
                                                    Hamburguesa doble con queso
                                                </div>
                                                <div class="text-caption">
                                                    Hamburguesa doble con queso
                                                </div>
                                                <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                                                    S/15.00
                                                </div>
                                            </div>
                                        </div>

                                    </v-card>
                                </v-col>
<!--                                <v-col cols="12" md="12">
                                    <v-card class="mx-auto" max-width="344" @click="addProduct(3)">
                                        <div class="d-flex flex-no-wrap">
                                            <v-img src="../assets/gaseosa.jpg" width="81px"></v-img>
                                            <div class="pl-2">
                                                <div class="subtitle-2 font-weight-bold">
                                                    Inca Cola 500ml
                                                </div>
                                                <div class="text-caption">
                                                    Inca Cola 500 ml
                                                </div>
                                                <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                                                    S/5.00
                                                </div>
                                            </div>
                                        </div>

                                    </v-card>
                                </v-col>-->
<!--                                <v-col cols="12" md="12">
                                    <v-card class="mx-auto" max-width="344" :to="'/cart'">
                                        <div class="d-flex flex-no-wrap">
                                            <v-img src="../assets/Product.png" width="81px"></v-img>
                                            <div class="pl-2">
                                                <div class="subtitle-2 font-weight-bold">
                                                    Spaghetti al Aglio e Olio
                                                </div>
                                                <div class="text-caption">
                                                    Spaghetti en ajo, aceite y finas hierbas, acompañado de champiñones.
                                                </div>
                                                <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                                                    S/32.00
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>-->
                            </v-row>
                        </v-window-item>
                        <v-window-item value="two">
                            <v-row class="my-3">
                                <v-col cols="12" md="12">
                                    <v-card class="mx-auto" max-width="344" @click="addProduct(1)">
                                        <div class="d-flex flex-no-wrap">
                                            <v-img src="../assets/hamburguesa_1.png" width="81px"></v-img>
                                            <div class="pl-2">
                                                <div class="subtitle-2 font-weight-bold">
                                                    Hamburguesa Clásica de Carne
                                                </div>
                                                <div class="text-caption">
                                                    Hamburguesa Clásica de Carne
                                                </div>
                                                <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                                                    S/12.00
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-window-item>
                        <v-window-item value="three">
                            <v-row class="my-3">
                                <v-col cols="12" md="12">
                                    <v-card class="mx-auto" max-width="344">
                                        <div class="d-flex flex-no-wrap">
                                            <v-img src="../assets/Product.png" width="81px"></v-img>
                                            <div class="pl-2">
                                                <div class="subtitle-2 font-weight-bold">
                                                    Spaghetti al Aglio e Olio
                                                </div>
                                                <div class="text-caption">
                                                    Spaghetti en ajo, aceite y finas hierbas, acompañado de champiñones.
                                                </div>
                                                <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                                                    S/32.00
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-window-item>
                    </v-window>
                </v-container>
            </div>
        </div>
        <v-card>
            <v-bottom-navigation>
                <div class="pr-8">
                    <div class="text-caption">{{ contadorProductos }} productos</div>
                    <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">S/ {{ total }}</div>
                </div>
                <v-btn depressed color="#BDBDBD" class="white--text" :to="'/checkout'">
                    <span class="py-2 px-6" style="background-color: #BDBDBD;border-radius: 10px;">Ordenar</span>
                </v-btn>
            </v-bottom-navigation>
        </v-card>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import Product from '@/components/Product.vue';

export default {
    name: 'Products',
    components: {
        NavBar,
        Product,
    },
    data() {
        return {
            tab: null,
            contadorProductos: 0,
            total: 0,
        }
    },
    methods: {
        addProduct(value) {
            this.contadorProductos++;
            if (value == 1) {
                this.total += 12;
            } else if (value == 2) {
                this.total += 15;
            }
        }
    }
}
</script>
<style scoped>
.white--text {
    color: white;
}

.v-btn__content {
    background-color: #03C3F4;
}

.v-bottom-navigation__content {
    justify-content: space-around !important;
}
</style>
