<template>
  <v-layout row style="background: linear-gradient(180deg, #0066FF 0%, #00CCFF 100%);">
    <v-col
      cols="12"
      sm="12"
    >
      <v-card max-height="80px" elevation="0" style="background: linear-gradient(180deg, #0066FF 0%, #00CCFF 100%);">
        <v-card-title class="blue white--text">
          <span class="text-h5 white--text">Menu</span>

          <v-spacer></v-spacer>

          <v-menu
            bottom
            left
          >
              <v-btn
                dark
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>

            <v-list>
            </v-list>
          </v-menu>
        </v-card-title>

      </v-card>
    </v-col>
  </v-layout>
</template>
<script>
export default {
  name: 'NavBar', 
  }
</script>