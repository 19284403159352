<template>
    <div>
        <NavBar/>
        <v-container>
            <v-row class="my-3">
                <v-col cols="12" md="12">
                    <v-card class="mx-auto" max-width="344">
                        <div class="d-flex flex-no-wrap">
                            <v-img src="../assets/hamburguesa_1.png" width="81px"></v-img>
                            <div class="pl-2">
                                <div class="subtitle-2 font-weight-bold">
                                    Hamburguesa Clásica de Carne
                                </div>
                                <div>
                                    <div class="text-caption">
                                        1 unidade(s)
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                                        S/ 12.00
                                    </div>
                                </div>
                                <div class="flex-row d-flex flex-no-wrap justify-end align-center py-3">
                                    <v-icon color="#0066FF">mdi-plus-circle-outline</v-icon>
                                    <div class="subtitle-2 font-weight-bold mx-3">
                                        1
                                    </div>
                                    <v-icon color="#0066FF">mdi-minus-circle-outline</v-icon>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="12">
                    <v-card class="mx-auto" max-width="344">
                        <div class="d-flex flex-no-wrap">
                            <v-img src="../assets/hamburguesa_2.png" width="81px"></v-img>
                            <div class="pl-2">
                                <div class="subtitle-2 font-weight-bold">
                                    Hamburguesa Clásica de Carne
                                </div>
                                <div>
                                    <div class="text-caption">
                                        1 unidade(s)
                                    </div>
                                    <v-spacer></v-spacer>

                                    <div class="subtitle-2 font-weight-bold" style="color: #0066FF;">
                                        S/ 12.00
                                    </div>
                                </div>
                                <div class="flex-row d-flex flex-no-wrap justify-end align-center py-3">
                                    <v-icon color="#0066FF">mdi-plus-circle-outline</v-icon>
                                    <div class="subtitle-2 font-weight-bold mx-3">
                                        1
                                    </div>
                                    <v-icon color="#0066FF">mdi-minus-circle-outline</v-icon>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <div class="subtitle-2 font-weight-bold">
                Pedido
            </div>
            <v-form>
                <v-row>
                    <v-col cols="7" sm="7">
                        <v-text-field outlined dense label="Ingresa tu nombre">
                        </v-text-field>
                    </v-col>
                    <v-col cols="5" sm="5">
                        <v-text-field outline single-line label="Mesa n°"></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            <div class="subtitle-2 font-weight-bold">
                Forma de pago
            </div>
            <v-row>
                <v-col cols="6" sm="6">
                    <v-btn outlined class="white--text" color="#0066FF">En caja</v-btn>
                </v-col>
                <v-col cols="6" sm="6">
                    <v-btn outlined color="#FFFFFF" style="color: #070D56;">En linea</v-btn>
                </v-col>
            </v-row>
            <div class="flex-row d-flex flex-no-wrap justify-space-between align-center py-3">
<!--                <v-icon color="#0066FF">mdi-plus-circle-outline</v-icon>-->
                <div class="subtitle-2 font-weight-bold mx-3">
                    3
                </div>
<!--                <v-icon color="#0066FF">mdi-minus-circle-outline</v-icon>-->
                <v-btn outlined color="#0066FF" class="white--text" @click="toConfirm" >Confirmar</v-btn>
            </div>

        </v-container>
    </div>
</template>
<style>
.white--text {
    color: white;
}

.subtitle-2 {
    color: #070D56;
}

.v-field__field {
    border: 1px solid #0066FF;
    filter: drop-shadow(0px 1px 3px #F0F0F0);
    border-radius: 10px;
}
</style>
<script>
import NavBar from '@/components/NavBar.vue'

export default {
    data() {
        return {
            value: 1,
            active: true,
        };
    },
    components: {NavBar},
    methods: {
        toConfirm() {
            let loader = this.$loading.show({
                color: '#0066FF',
                backgroundColor: 'rgba(0,0,0,0.8)',
            });
            setTimeout(() => {
                this.$router.push('confirmar');
                loader.hide();
            }, 1000) ;
        }
    }
}
</script>
