<template>
    <div>
        <NavBar/>
        <v-container>
            <div class="text-h5 font-weight-bold my-4" style="color: #070D56;">Promociones</div>
            <v-img src="../assets/banner.png" contain max-width=""/>
            <div class="text-h5 font-weight-bold my-4" style="color: #070D56;">Carta</div>
            <Categories/>
        </v-container>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue';
import Categories from '@/components/Categories.vue';

export default {
    name: 'Home',
    components: {
        NavBar,
        Categories,
    },
}
</script>
