<template>
    <v-row>
        <v-col cols="6" md="6">
            <v-card :to="'/products'" elevation="0">
                <v-img src="../assets/Menu.png" class="white--text align-center text-center">
                    <div class="font-weight-medium subtitle-">Menu</div>
                </v-img>
            </v-card>
        </v-col>
        <v-col cols="6" md="6">
            <v-card :to="'/products'" elevation="0">
                <v-img src="../assets/Bebidas.png" class="white--text align-center text-center">
                    <div class="font-weight-medium subtitle-">Bebidas</div>
                </v-img>
            </v-card>
        </v-col>
        <v-col cols="6" md="6">
            <v-card :to="'/products'" elevation="0">
                <v-img src="../assets/Pastas.png" class="white--text align-center text-center">
                    <div class="font-weight-medium subtitle-">Pastas</div>
                </v-img>
            </v-card>
        </v-col>
        <v-col cols="6" md="6">
            <v-card :to="'/products'" elevation="0">
                <v-img src="../assets/Pizzas.png" class="white--text align-center text-center">
                    <div class="font-weight-medium subtitle-">Pizzas</div>
                </v-img>
            </v-card>
        </v-col>
        <v-col cols="6" md="6">
            <v-card :to="'/products'" elevation="0">
                <v-img src="../assets/Pollo.png" class="white--text align-center text-center">
                    <div class="font-weight-medium subtitle-">Pollo</div>
                </v-img>
            </v-card>
        </v-col>
        <v-col cols="6" md="6">
            <v-card :to="'/products'" elevation="0">
                <v-img src="../assets/Hamburguesa.png" class="white--text align-center text-center">
                    <div class="font-weight-medium subtitle-">Hamburguesa</div>
                </v-img>
            </v-card>
        </v-col>
        <v-col cols="6" md="6">
            <v-card :to="'/products'" elevation="0">
                <v-img src="../assets/Postre.png" class="white--text align-center text-center">
                    <div>Postre</div>
                </v-img>
            </v-card>
        </v-col>
        <v-col cols="6" md="6">
            <v-card :to="'/products'" elevation="0">
                <v-img src="../assets/Ensaladas.png" class="white--text align-center text-center">
                    <div>Ensaladas</div>
                </v-img>
            </v-card>
        </v-col>
    </v-row>
</template>
<style>
.white--text {
    color: white;
}
</style>
<script>
export default {
    name: 'Categories',
}
</script>
