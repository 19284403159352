<template>
  <v-container>

    <v-img src="../assets/image1.png" class="my-3" contain height="200" />
    <div class="text-h5 font-weight-medium text-center my-4" style="color: #070D56;">¡Bienvenido!</div>
    <p class="text-center mb-10" style="color: #070D56;">Puedes revisar la carta y hacer tu pedido desde acá.</p>
    <v-btn block sm color="#0066FF" class="white--text mb-14" :to="'/home'">
      Iniciar pedido
    </v-btn>
    <v-img src="../assets/logo.png" class="my-3 mx-auto" contain width="80" justify="center"/>
  </v-container>
</template>
<script>
import { defineComponent } from 'vue';

// Components

export default defineComponent({
  name: 'HomeView',

});
</script>
